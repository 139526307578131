<template>
  <v-app-bar
    class="sub-bar"
    density="comfortable"
    elevation="0"
    style="border-bottom: 10px"
  >
    <template v-slot:prepend>
      <v-img
        class="ml-1"
        width="180px"
        @click="$router.push($store.state.topUrl + $store.state.slug)"
        src="@/assets/logo_header.png"
      ></v-img>
    </template>

    <v-app-bar-title class="pt-3" style="font-size: 16px; font-weight: bold"
      >配達注文用サイト</v-app-bar-title
    >

    <template v-slot:append>
      <span
        @click="$router.push({ name: 'orderhistory' })"
        class="mr-2 d-none d-sm-block"
        >注文履歴</span
      >
      <v-img
        class="mr-2 mr-md-8"
        width="40px"
        @click="$router.push({ name: 'orderhistory' })"
        src="@/assets/order_history.jpg"
      ></v-img>
    </template>
  </v-app-bar>
  <v-container fluid style="border-bottom: 1px solid">
    <v-row class="pa-1">
      <v-sheet class="mr-5"> 店舗: {{ storeName }} </v-sheet>
      <v-sheet> TEL: {{ storeTel }} </v-sheet>
    </v-row>
    <v-row class="pa-1">
      <v-sheet> お届け先: {{ companyName }}様 </v-sheet>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MainHeader",
  data() {
    return {
      storeName: "",
      storeTel: "",
      companyName: "",
    };
  },
  created() {
    this.storeName = this.$store.state.storeData["0"]["username"];
    this.storeTel = this.$store.state.storeData["0"]["tel"];
    this.companyName = this.$store.state.companyData["0"]["name"];
  },
};
</script>

<style lang="scss">
.sub-bar {
  .v-toolbar__content {
    border-bottom: 2px solid rgb(237, 28, 36);
  }
}
</style>
