<template>
  <v-app-bar
    class="sub-bar"
    density="comfortable"
    elevation="0"
    style="border-bottom: 10px"
  >
    <v-app-bar-title
      @click="$router.push({ name: 'admin' })"
      style="font-size: 16px; font-weight: bold"
      >配達注文用サイト管理画面</v-app-bar-title
    >
    <template v-slot:append>{{ storeName }} </template>
  </v-app-bar>
</template>

<script>
export default {
  name: "AdminHeader",
  data() {
    return {
      storeName: "",
    };
  },
  created() {
    this.storeName = this.$store.state.userName;
  },
};
</script>

<style lang="scss">
.sub-bar {
  .v-toolbar__content {
    border-bottom: 2px solid rgb(237, 28, 36);
  }
}
</style>
