<template>
  <div>
    <main-header />
    <v-overlay :model-value="loading" class="align-center justify-center">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-card class="py-0">
      <v-list class="py-0" lines="one">
        <v-list-item v-for="message in store_message_list" :key="message.id">
          <v-list-title>
            {{ message.message }}
          </v-list-title>
          <template v-slot:prepend>
            <v-icon>mdi-alert-box-outline</v-icon>
          </template></v-list-item
        >
      </v-list>
    </v-card>
    <div class="pa-5 pa-md-14">
      <h2 style="text-align: center">メニュー</h2>
      <v-expansion-panels variant="accordion" multiple class="my-4">
        <v-expansion-panel
          v-for="(category, index) in category_list"
          :key="index"
          :title="category.category"
          style="position: relative"
          elevation="0"
          :class="{ panellast: index === category_list.length - 1 }"
          ><v-expansion-panel-text>
            <v-container>
              <v-row>
                <template
                  v-for="item in itemlist_each_category[index]"
                  :key="item.id"
                >
                  <v-col cols="6" lg="2" md="3" sm="6">
                    <v-card class="mx-auto" @click.stop="onClickBtn(item)">
                      <v-list-item class="pa-0">
                        <img
                          :src="item.image"
                          @load="imgLoad"
                          style="object-fit: contain; width: 100%"
                        />
                        <v-divider class="mx-1 mb-1"></v-divider>
                        <v-card-subtitle
                          class="font-weight-bold text-subtitle-1 my-3 text-wrap"
                        >
                          {{ item.title }}
                        </v-card-subtitle>
                        <v-divider class="mx-1 mb-1"></v-divider>
                        <div class="mr-4 my-2 text-h6" style="text-align: end">
                          {{ item.default_price }}円
                          <v-chip-group class="ml-2">
                            <v-chip v-if="item.soon_end">まもなく終了</v-chip>
                            <v-chip v-if="salesEndItems.includes(item.id)"
                              >販売終了</v-chip
                            >
                            <v-chip v-if="todaySoldOutItems.includes(item.id)"
                              >本日売り切れ</v-chip
                            >
                            <v-chip v-if="item.in_campaign"
                              >キャンペーン中</v-chip
                            >
                          </v-chip-group>
                        </div>
                        <!-- <v-card-subtitle>
                      {{ item.default_price }}円
                    </v-card-subtitle> -->
                      </v-list-item>
                    </v-card>
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <v-dialog v-model="dialog" v-if="currentItem" max-width="400px" activator>
      <v-card>
        <v-card-title
          class="text-h5 font-weight-bold mt-2"
          style="text-align: center"
          >{{ currentItem.title }}</v-card-title
        >
        <v-divider class="mx-2"></v-divider>
        <template v-for="type in rice_type_list" :key="type.id">
          <template v-if="currentItem.rice_type.includes(type.id)">
            <v-container>
              <v-row v-if="type.type != ''">
                <v-col
                  cols="6"
                  class="pa-5"
                  style="text-align: center; font-weight: bold"
                >
                  {{ type.type }}
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model.number="editedItem[type.type]"
                    style="height: 40px"
                    bg-color="transparent"
                    density="compact"
                    hide-details="auto"
                    inputmode="numeric"
                    type="number"
                  >
                    <template v-slot:prepend>
                      <v-icon
                        v-if="editedItem[type.type] > 0"
                        color="red"
                        @click="editedItem[type.type]--"
                      >
                        mdi-minus-circle
                      </v-icon>
                      <v-icon v-if="editedItem[type.type] < 1">
                        mdi-minus-circle
                      </v-icon>
                    </template>

                    <template v-slot:append>
                      <v-icon color="red" @click="editedItem[type.type]++">
                        mdi-plus-circle
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="type.type == ''">
                <v-col cols="3"></v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model.number="editedItem[type.type]"
                    style="height: 40px"
                    bg-color="transparent"
                    density="compact"
                    hide-details="auto"
                    inputmode="numeric"
                    type="number"
                  >
                    <template v-slot:prepend>
                      <v-icon
                        v-if="editedItem[type.type] > 0"
                        color="red"
                        @click="editedItem[type.type]--"
                      >
                        mdi-minus-circle
                      </v-icon>
                      <v-icon v-if="editedItem[type.type] < 1">
                        mdi-minus-circle
                      </v-icon>
                    </template>

                    <template v-slot:append>
                      <v-icon color="red" @click="editedItem[type.type]++">
                        mdi-plus-circle
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="3"></v-col>
              </v-row>
            </v-container>
          </template>
        </template>
        <div class="pa-2 mt-2">
          <div>
            <v-btn
              v-if="
                cartEditing &&
                !(sold_out_btn_disabled || today_sold_out_btn_disabled)
              "
              class="text-h6"
              size="large"
              block
              @click="addCart"
              prepend-icon="mdi-cart-arrow-down"
              style="background-color: rgb(237, 28, 36); color: white"
            >
              変更する
            </v-btn>
            <v-btn
              :disabled="sold_out_btn_disabled || today_sold_out_btn_disabled"
              v-if="
                !cartEditing &&
                !(sold_out_btn_disabled || today_sold_out_btn_disabled)
              "
              class="text-h6"
              size="large"
              block
              @click="addCart"
              prepend-icon="mdi-cart-arrow-down"
              style="background-color: rgb(237, 28, 36); color: white"
            >
              カゴに追加
            </v-btn>
            <v-btn
              v-if="sold_out_btn_disabled"
              class="text-h6"
              size="large"
              block
              prepend-icon="mdi-cart-arrow-down"
              style="background-color: rgba(237, 28, 36, 0.7); color: white"
            >
              終了しました
            </v-btn>
            <v-btn
              v-if="today_sold_out_btn_disabled"
              class="text-h6"
              size="large"
              block
              prepend-icon="mdi-cart-arrow-down"
              style="background-color: rgba(237, 28, 36, 0.7); color: white"
            >
              本日売り切れ
            </v-btn>
          </div>
          <div>
            <v-btn block size="large" class="mt-3" @click="close">閉じる</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_cart" class="px-4" max-width="600px" scrollable>
      <v-card>
        <v-card-title
          style="text-align: center"
          class="text-h5 font-weight-bold my-3"
          >注文内容</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text class="py-0" style="max-height: 400px">
          <template v-for="(item, id) in cartItem" :key="id">
            <v-container>
              <v-row>
                <v-col>
                  <v-row>
                    <v-col class="text-center font-weight-bold text-h6 pa-1">
                      {{ item_list.find((element) => element.id == id).title }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="1"
                      class="mr-1"
                      align-self="center"
                      @click="removeItem(id)"
                    >
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-col>
                    <v-col cols="9" align-self="center" class="text-right">
                      <div v-for="(quantity, ricetype) in item" :key="ricetype">
                        <div class="mt-1" v-if="quantity != 0">
                          {{ ricetype }} {{ quantity }} :
                          {{ subTotal(id, quantity, ricetype) }}円
                        </div>
                      </div>
                    </v-col>
                    <v-col
                      cols="1"
                      class="pl-2"
                      align-self="center"
                      @click="editInCart(id)"
                    >
                      <v-icon>mdi-pencil-box-outline</v-icon>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
            <v-divider class="mx-2"></v-divider>
          </template>
          <v-container>
            <v-row>
              <v-col cols="5" class="text-center">
                商品数：{{ cartItemTotalCount }} 点
              </v-col>
              <v-col cols="7" class="text-center">
                合計金額：{{ totalAmmount.toLocaleString() }} 円</v-col
              >
            </v-row>
          </v-container>
        </v-card-text>
        <v-btn
          @click="dialog_message = true"
          variant="text"
          style="color: brown"
          >ご要望等はこちら</v-btn
        >
        <div class="pa-2 mt-2">
          <div>
            <v-btn
              :disabled="loading || !isCartItem"
              class="text-h6"
              size="large"
              block
              @click="order"
              style="background-color: rgb(237, 28, 36); color: white"
            >
              注文する
            </v-btn>
          </div>
          <div>
            <v-btn block size="large" class="mt-3" @click="dialog_cart = false"
              >閉じる</v-btn
            >
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_message" style="width: 400px">
      <v-card>
        <v-container fluid>
          <v-textarea
            v-model="order_message"
            label="ご要望等"
            auto-grow
            variant="outlined"
            rows="10"
            row-height="20"
            clearable
          ></v-textarea>
        </v-container>
        <v-card-actions class="pa-2">
          <v-row class="pa-2">
            <v-col>
              <v-btn @click="dialog_message = false" block variant="outlined"
                >閉じる</v-btn
              >
            </v-col>
            <v-col>
              <v-btn @click="dialog_message = false" block variant="outlined"
                >OK</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="orderDone" max-width="400px">
      <v-container>
        <v-card v-if="!orderError">
          <v-card-title
            class="text-h6 font-weight-bold mt-2 text-wrap"
            style="text-align: center"
            >ご注文ありがとうございます。送信に成功しました。</v-card-title
          >
          <v-card-text>
            <v-img
              src="@/assets/thanks.png"
              max-width="180px"
              class="mx-auto"
            ></v-img>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :to="{ name: 'orderhistory' }"
              class="mx-auto text-none px-2"
              variant="outlined"
              block
              color="indigo-darken-3"
              size="x-large"
            >
              注文確認はこちら
            </v-btn></v-card-actions
          >
          <v-card-actions>
            <v-btn
              class="mx-auto text-none px-2 mb-2"
              variant="outlined"
              block
              color="indigo-darken-3"
              size="x-large"
              @click="orderDaneDialogClose"
              >閉じる
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-if="orderError">
          <v-card-title
            class="text-h6 font-weight-bold mt-2 text-wrap"
            style="text-align: center"
            >申し訳ありません</v-card-title
          >
          <v-card-text>
            <v-img
              src="@/assets/sorry.png"
              max-width="180px"
              class="mx-auto"
            ></v-img>
          </v-card-text>
          <v-card-text class="text-h6 font-weight-bold mt-2 text-wrap">
            {{ this.soldOutItem.title }}は、売り切れか販売終了しています。
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mx-auto text-none px-2"
              variant="outlined"
              block
              color="indigo-darken-3"
              size="x-large"
              @click="orderDaneDialogClose"
              >閉じる</v-btn
            ></v-card-actions
          >
        </v-card>
      </v-container>
    </v-dialog>
  </div>
  <div style="position: relative">
    <v-btn
      @click="dialog_cart = true"
      v-if="isCartItem"
      color="red"
      size="x-large"
      style="position: fixed; bottom: 4rem; right: 2rem; z-index: 100"
    >
      <v-icon size="x-large" color="white" class="mr-3">mdi-cart-check</v-icon>
      商品数： {{ cartItemTotalCount }}
    </v-btn>
  </div>
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import { CSRF_TOKEN } from "../common/csrf_token.js";

export default {
  name: "ItemListView",
  data() {
    return {
      item_list: [],
      category_list: [],
      rice_type_list: [],
      item_price_list: {},
      rice_type_price_list: {},
      store_message_list: [],
      todaySoldOutItems: [],
      salesEndItems: [],
      order_message: "",
      dialog: false,
      dialog_cart: false,
      dialog_message: false,
      cartEditing: false,
      sold_out_btn_disabled: false,
      today_sold_out_btn_disabled: false,
      loading: true,
      imgLoading: true,
      orderError: false,
      orderDone: false,
      currentItem: "",
      editedItem: {},
      defaultItem: {},
      soldOutItem: {},
    };
  },
  computed: {
    cartItem: function () {
      return this.$store.state.cartItem;
    },
    isCartItem: function () {
      if (Object.keys(this.$store.state.cartItem).length == 0) {
        return false;
      } else {
        return true;
      }
    },
    cartItemTotalCount: function () {
      let count = 0;
      for (const i in this.cartItem) {
        for (const key in this.cartItem[i]) {
          if (!this.cartItem[i][key] == "") {
            count += this.cartItem[i][key];
          }
        }
      }
      console.log(count);
      return count;
    },
    itemlist_each_category: function () {
      const itemlist_each_category = [];
      for (const index in this.category_list) {
        const list = this.item_list.filter(
          (item) => item.category == this.category_list[index].id
        );
        itemlist_each_category.push(list);
      }
      return itemlist_each_category;
    },
    totalAmmount: function () {
      let ammount = 0;
      for (let item in this.$store.state.cartItem) {
        for (let rice_type in this.$store.state.cartItem[item]) {
          ammount +=
            this.$store.state.cartItem[item][rice_type] *
            (this.item_price_list[item] + this.rice_type_price_list[rice_type]);
        }
      }
      return ammount;
    },
  },
  methods: {
    imgLoad() {
      this.imgLoad = false;
    },
    subTotal(id, quantity, rice_type) {
      const item = this.item_list.find((element) => element.id == id);
      return (
        parseInt(quantity) *
        parseInt(item.default_price + this.rice_type_price_list[rice_type])
      );
    },
    editInCart(id) {
      const item = this.item_list.find((element) => element.id == id);
      this.currentItem = item;
      this.editedItem = Object.assign({}, this.$store.state.cartItem[item.id]);
      this.editedItem["id"] = id;
      this.dialog_cart = false;
      this.dialog = true;
      this.cartEditing = true;
    },
    removeItem(id) {
      this.$store.commit("removeItem", id);
    },
    getData() {
      let endpoint =
        this.$store.state.url + "itemlist/" + this.$store.state.slug;
      fetch(endpoint, {
        method: "GET",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
        },
        body: null,
      })
        .then((response) => {
          if (response.status == 200) {
            return response.json();
          } else if (response.status == 404) {
            return null;
          } else {
            return null;
          }
        })
        .then((data) => {
          this.$store.commit("setStoreData", data["store_data"]);
          this.$store.commit("setCompanyData", data["company_data"]);
          this.item_list = data["item_list"];
          this.rice_type_list = data["rice_type_list"];
          this.category_list = data["category_list"];
          this.store_message_list = data["message_list"];
          for (let index in data["sold_out_items"]) {
            this.todaySoldOutItems.push(data["sold_out_items"][index]["item"]);
          }
          for (let index in data["sales_end_items"]) {
            this.salesEndItems.push(data["sales_end_items"][index]["item"]);
          }

          for (const item in this.item_list) {
            const id = this.item_list[item]["id"];
            const default_price = this.item_list[item]["default_price"];
            this.item_price_list[id] = default_price;
          }

          for (const type in this.rice_type_list) {
            const rice_type = this.rice_type_list[type]["type"];
            const price_difference =
              this.rice_type_list[type]["price_difference"];
            this.rice_type_price_list[rice_type] = price_difference;
          }

          let item_dict = new Object();
          let rice_type_dict = new Object();
          for (let type in this.rice_type_list) {
            rice_type_dict[this.rice_type_list[type].type] = 0;
          }
          item_dict.id = "";
          Object.assign(item_dict, rice_type_dict);
          Object.assign(this.editedItem, item_dict);
          Object.assign(this.defaultItem, item_dict);
          this.loading = false;
        });
    },
    onClickBtn(item) {
      // 本番環境でごく稀に(2000分の１ぐらい)前のアイテムのデータが残ることがあったので追加した
      this.editedItem = Object.assign({}, this.defaultItem);

      this.currentItem = item;
      this.editedItem.id = item.id;
      // 販売終了時
      if (this.salesEndItems.includes(item.id)) {
        this.sold_out_btn_disabled = true;
      } else {
        this.sold_out_btn_disabled = false;
      }
      // 本日売り切れ時
      if (this.todaySoldOutItems.includes(item.id)) {
        this.today_sold_out_btn_disabled = true;
      } else {
        this.today_sold_out_btn_disabled = false;
      }
      if (item.id in this.$store.state.cartItem) {
        // すでにカートに存在している場合
        this.editedItem = Object.assign(
          {},
          this.$store.state.cartItem[item.id]
        );
        this.editedItem.id = item.id;
      }
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.currentItem = "";
      if (this.cartEditing == true) {
        this.dialog_cart = true;
        this.cartEditing = false;
      }
    },
    addCart() {
      var total = 0;
      for (let key in this.editedItem) {
        if (key != "id") {
          if (!isNaN(this.editedItem[key])) {
            total = this.editedItem[key] + total;
          }
        }
      }
      // 商品数が０の場合カートから削除
      if (total == 0) {
        this.$store.commit("removeCart", this.editedItem);
      } else {
        this.$store.commit("addCart", this.editedItem);
      }
      this.editedItem = Object.assign({}, this.defaultItem);
      // 本番環境でごく稀に前のアイテムのデータが残ることがあったので追加した
      this.editedItem = Object.assign({}, this.defaultItem);

      this.dialog = false;
      if (this.cartEditing == true) {
        if (total == 0) {
          this.dialog_cart = true;
          setTimeout(() => {
            this.cartEditing = false;
          }, 1000);
        } else {
          this.dialog_cart = true;
          setTimeout(() => {
            this.cartEditing = false;
          }, 1000);
        }
      }
    },
    order() {
      this.loading = true;
      let endpoint = this.$store.state.url + "order/" + this.$store.state.slug;
      const cart = this.$store.state.cartItem;
      const order_message = this.order_message;
      fetch(endpoint, {
        method: "POST",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
          "X-CSRFTOKEN": CSRF_TOKEN,
        },
        body: JSON.stringify({
          cart,
          message: order_message,
        }),
      })
        .then((response) => {
          if (response.status == 201) {
            this.dialog_cart = false;
            this.loading = false;
            this.order_message = "";
            this.$store.commit("orderSuccess");
            this.orderDone = true;
          } else {
            this.orderDone = true;
            this.orderError = true;
            this.loading = false;
            return response.json();
          }
        })
        .then((data) => {
          this.soldOutItem = Object.assign({}, data);
        });

      this.dialog_cart = false;
    },
    orderDaneDialogClose() {
      this.orderDone = false;
      this.orderError = false;
      this.$store.commit("removeCart", this.soldOutItem);
      this.soldOutItem = Object.assign({}, {});
    },
  },
  components: {
    MainHeader,
  },
  created() {
    this.$store.commit("getCompanySlug", this.$route.path);
    this.getData();
  },
};
</script>

<style lang="scss">
body {
  font-size: 100%;
}
.v-expansion-panel {
  min-height: 70px;
  // border-top: 1px solid rgb(237, 28, 36);
  border-top: 1px solid;
  border-radius: 0%;
}
.panellast {
  // border-bottom: 1px solid rgb(237, 28, 36);
  border-bottom: 1px solid;
}
.v-expansion-panel-title {
  font-size: 1rem;
  min-height: 70px;
  font-weight: bold;
  padding-left: 30px;
}
.v-expansion-panel-title::before {
  content: "";
  margin-right: 10px;
  background-image: url(@/assets/icon_menu.png);
  display: inline-block;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  background-size: contain;
}
i {
  color: rgb(237, 28, 36);
  transition: 0.3s;
}
.v-expansion-panel-text__wrapper {
  background-color: rgba(251, 252, 227, 0.3);
  padding: 0;
}
.v-chip {
  color: red;
}
input[type="number"] {
  text-align: center;
  font-weight: bold;
  font-size: 150%;
}
.v-field__input {
  height: 40px;
}
.mdi-cart-arrow-down {
  color: white;
}
// chrome,safari
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
// firefox,IE
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
