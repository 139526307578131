<template>
  <admin-header />
  <v-container class="mt-12">
    <v-sheet width="300" class="mx-auto">
      <v-form fast-fail v-model="valid" @submit.prevent="onsubmit">
        <v-text-field v-model="userName" label="店舗名"></v-text-field>

        <v-text-field v-model="password" label="パスワード"></v-text-field>

        <v-btn
          height="50"
          color="deep-orange-lighten-1"
          type="submit"
          block
          class="mt-2"
          >ログイン</v-btn
        >
      </v-form>
    </v-sheet>
  </v-container>
</template>

<script>
import AdminHeader from "@/components/AdminHeader.vue";
import { CSRF_TOKEN } from "../common/csrf_token.js";

export default {
  name: "LoginView",
  data() {
    return {
      userName: this.$store.state.userName,
      password: this.$store.state.password,
    };
  },
  methods: {
    onsubmit() {
      let endpoint = this.$store.state.url + "auth/jwt/create/";
      fetch(endpoint, {
        method: "POST",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
          "X-CSRFTOKEN": CSRF_TOKEN,
        },
        body: JSON.stringify({
          username: this.userName,
          password: this.password,
        }),
      })
        .then((response) => {
          if (response.status == 204) {
            return "";
          } else if (response.status == 404) {
            return null;
          } else {
            return response.json();
          }
        })
        .then((data) => {
          this.$store.commit("setToken", data);
          this.$store.commit("setUserName", {
            userName: this.userName,
            password: this.password,
          });
        })
        .then(() => {
          this.$router.push({
            name: "admin",
          });
        });
    },
  },
  components: {
    AdminHeader,
  },
};
</script>
