<template>
  <div>
    <admin-header />
  </div>
  <v-container>
    <v-row>
      <v-col class="mx-10 mt-5">
        <v-btn
          class="text-h6"
          height="72"
          min-width="300"
          color="deep-orange-lighten-1"
          @click="$router.push({ name: 'storemessage' })"
          >店舗メッセージ編集</v-btn
        >
      </v-col>
      <v-col class="mx-10">
        <v-btn
          class="text-h6"
          height="72"
          min-width="300"
          color="deep-orange-lighten-1"
          block
          @click="$router.push({ name: 'soldout' })"
          >売切・終売設定</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AdminHeader from "@/components/AdminHeader.vue";
import { VCol } from "vuetify/lib/components";

export default {
  name: "AdminView",
  components: {
    AdminHeader,
    VCol,
  },
};
</script>
