<template>
  <div>
    <admin-header />
  </div>
  <div class="text-h6 pa-2 ml-2">店舗メッセージ登録</div>
  <div>
    <div class="py-3 px-12">
      <v-btn
        class="text-h6"
        color="deep-orange-lighten-1"
        height="60"
        width="300"
        @click="openDialog()"
        >新規登録</v-btn
      >
    </div>

    <template v-for="message in store_message_list" :key="message.id">
      <v-card class="mx-auto mb-3" max-width="344" variant="outlined">
        <v-card-text>
          {{ message.message }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" variant="outlined" v-if="message.publish"
            >公開中</v-btn
          >
          <v-btn color="blue" variant="outlined" v-else>非公開</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="deep-orange-lighten-1"
            variant="flat"
            @click="openDialog(message)"
          >
            編集
          </v-btn>
          <v-btn
            color="red-darken-2"
            variant="outlined"
            @click="deleteMessage(message.id)"
          >
            削除
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </div>

  <v-dialog v-model="dialogMessage" style="width: 400px">
    <v-card class="pa-2">
      <v-container fluid class="pa-2">
        <v-textarea
          v-model="storeMessage"
          label="お知らせ"
          auto-grow
          variant="outlined"
          rows="10"
          row-height="20"
          clearable
        ></v-textarea>
      </v-container>
      <v-card-actions class="pa-2">
        <v-row class="pa-2">
          <v-col cols="6">
            公開する：<input type="checkbox" v-model="isPublish" />
          </v-col>
          <v-col cols="3">
            <v-btn
              color="deep-orange-lighten-1"
              variant="flat"
              @click="postStoreMessage"
              >登録</v-btn
            >
          </v-col>
          <v-col cols="3">
            <v-btn @click="dialogMessage = false" variant="outlined"
              >閉じる</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AdminHeader from "@/components/AdminHeader.vue";
import { CSRF_TOKEN } from "../common/csrf_token.js";

export default {
  name: "AdminStoreMessageView",
  data() {
    return {
      store_message_list: [],
      dialogMessage: false,
      storeMessage: "",
      messageID: "",
      isPublish: true,
    };
  },
  components: {
    AdminHeader,
  },
  methods: {
    getStoreMessage() {
      let endpoint = this.$store.state.url + "adminsite/storemessage";
      fetch(endpoint, {
        method: "GET",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
          "X-CSRFTOKEN": CSRF_TOKEN,
          Authorization: "JWT " + this.$store.state.accessToken,
        },
        body: null,
      })
        .then((response) => {
          if (response.status == 200) {
            return response.json();
          } else if (response.status == 401) {
            this.$store.commit("setAccessToken");
            this.getStoreMessage();
          } else {
            return null;
          }
        })
        .then((data) => {
          console.log(data);
          this.store_message_list = data;
        });
    },
    openDialog(message) {
      this.dialogMessage = true;
      this.storeMessage = "";
      this.messageID = "";
      this.isPublish = true;
      if (message) {
        this.storeMessage = message.message;
        this.messageID = message.id;
        this.isPublish = message.publish;
      }
    },
    postStoreMessage() {
      let endpoint = this.$store.state.url + "adminsite/storemessage";
      fetch(endpoint, {
        method: "POST",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
          "X-CSRFTOKEN": CSRF_TOKEN,
          Authorization: "JWT " + this.$store.state.accessToken,
        },
        body: JSON.stringify({
          id: this.messageID,
          message: this.storeMessage,
          publish: this.isPublish,
        }),
      }).then((response) => {
        if (response.status == 201) {
          this.storeMessage = "";
          this.dialogMessage = false;
          this.getStoreMessage();
        } else if (response.status == 401) {
          this.$store.commit("setAccessToken");
          this.postStoreMessage();
        } else {
          return null;
        }
      });
    },
    deleteMessage(id) {
      let endpoint = this.$store.state.url + "adminsite/storemessage";
      fetch(endpoint, {
        method: "DELETE",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
          "X-CSRFTOKEN": CSRF_TOKEN,
          Authorization: "JWT " + this.$store.state.accessToken,
        },
        body: JSON.stringify({
          id: id,
        }),
      }).then((response) => {
        if (response.status == 201) {
          this.getStoreMessage();
        } else if (response.status == 401) {
          this.$store.commit("setAccessToken");
          this.postStoreMessage();
        } else {
          return null;
        }
      });
    },
  },
  created() {
    this.getStoreMessage();
  },
};
</script>
