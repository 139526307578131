import { createStore } from "vuex";
import { CSRF_TOKEN } from "@/common/csrf_token";
import router from "../router/index";
// 本番はコメントを外す
import createPersistedState from "vuex-persistedstate";
export default createStore({
  // 本番はコメントを外す
  plugins: [createPersistedState()],
  state: {
    url: "https://hm-delivery.top/api/",
    // url: "http://192.168.2.107:4000/api/",
    topUrl: "/menu/",
    slug: "",
    companyData: {},
    storeData: {},
    cartItem: {},
    userName: "",
    password: "",
    accessToken: "",
    refreshToken: "",
  },
  getters: {},
  mutations: {
    getCompanySlug(state, path) {
      state.slug = path.split("/").slice(-1)[0];
    },
    setStoreData(state, data) {
      state.storeData = Object.assign({}, data);
    },
    setCompanyData(state, data) {
      state.companyData = Object.assign({}, data);
    },
    addCart(state, item) {
      const id = item.id;
      delete item.id;
      state.cartItem[id] = item;
    },
    removeCart(state, item) {
      const id = item.id;
      delete state.cartItem[id];
    },
    removeItem(state, id) {
      delete state.cartItem[id];
    },
    orderSuccess(state) {
      state.cartItem = {};
    },
    setToken(state, data) {
      state.accessToken = data["access"];
      state.refreshToken = data["refresh"];
    },
    setAccessToken(state) {
      let endpoint = state.url + "auth/jwt/refresh/";
      fetch(endpoint, {
        method: "POST",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
          "X-CSRFTOKEN": CSRF_TOKEN,
        },
        body: JSON.stringify({
          refresh: state.refreshToken,
        }),
      })
        .then((response) => {
          if (response.status == 204) {
            return "";
          } else if (response.status == 404) {
            return null;
          } else if (response.status == 401) {
            router.push({
              name: "login",
            });
          } else {
            return response.json();
          }
        })
        .then((data) => {
          state.accessToken = data["access"];
        });
    },
    setUserName(state, data) {
      console.log(data);
      // console.log(password);
      state.userName = data["userName"];
      state.password = data["password"];
    },
  },
  actions: {},
  modules: {},
});
