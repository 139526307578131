<template>
  <main-header />
  <div class="pa-3">
    <v-container>
      <v-row>
        <v-col class="px-0">
          <datepicker
            v-model="startDate"
            :format="format"
            :enable-time-picker="true"
            week-start="0"
            locale="ja"
            placeholder="日付を選択"
            auto-apply
            hide-input-icon
          />
        </v-col>
        <v-col cols="1" class="d-flex align-center">~</v-col>
        <v-col class="px-0">
          <datepicker
            v-model="endDate"
            :format="format"
            :enable-time-picker="false"
            week-start="0"
            locale="ja"
            placeholder="日付を選択"
            :show-now-button="true"
            auto-apply
            hide-input-icon
          ></datepicker>
        </v-col>
      </v-row>
    </v-container>
    <v-btn
      class="mb-3"
      block
      color="lime-lighten-5"
      size="large"
      density="comfortable"
      @click="getPastOrders"
      >過去の注文を検索</v-btn
    >
    <h2
      v-if="Object.keys(pastOrders).length == 0"
      style="text-align: center"
      class="mt-3"
    >
      本日の注文一覧
    </h2>
    <v-row v-if="Object.keys(pastOrders).length != 0" justify="end">
      <v-col class="text-h6 ml-5">
        期間中合計個数:{{ periodTotalItemCount }}
      </v-col>
      <v-col class="text-h6">
        期間中合計金額:{{ periodTotalPrice.toLocaleString() }}円
      </v-col>
    </v-row>
    <template v-for="order in orders" :key="order">
      <v-divider :thickness="4" class="mt-2 border-opacity-75"></v-divider>
      <v-container class="pb-0">
        <v-row>
          <v-col cols="7">
            {{ order["ordered_date"] }}
          </v-col>
          <v-col cols="5">
            <span>店舗確認: </span>
            <span
              v-if="order.store_confirming"
              style="color: red; font-weight: bold"
            >
              済み
            </span>
            <span v-else>未</span>
          </v-col>
        </v-row>
        <v-divider class="my-2"></v-divider>
        <div
          class="ml-2"
          v-for="order_item in order.order_items"
          :key="order_item"
        >
          <v-container>
            <v-row>
              <v-col class="py-0">
                {{ order_item.title }} {{ order_item.rice_type }}
              </v-col>
              <v-col class="py-0">
                {{ order_item.price.toLocaleString() }} 円 ×
                {{ order_item.quantity }} =
                {{
                  subTotal(
                    order_item.quantity,
                    order_item.price
                  ).toLocaleString()
                }}
                円
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-container>
      <v-container class="py-0">
        <v-divider class="my-2"></v-divider>
        <v-row>
          <v-col cols="5"></v-col>
          <v-col cols="3"> 個数: {{ itemCount(order) }} </v-col>
          <v-col cols="4">
            小計: {{ total_each_order(order).toLocaleString() }} 円
          </v-col>
        </v-row>
      </v-container>
    </template>
    <v-divider :thickness="4" class="mt-2 border-opacity-75"></v-divider>
    <v-container
      class="font-weight-black text-h6"
      v-if="Object.keys(orders).length > 1"
    >
      <v-row>
        <v-col cols="1"></v-col>
        <v-col cols="5">合計個数 : {{ itemTotalCount }}</v-col>
        <v-col cols="6"
          >合計金額 : {{ totalAmmount.toLocaleString() }} 円</v-col
        >
      </v-row>
    </v-container>
    <v-expansion-panels variant="accordion" class="my-4">
      <v-expansion-panel
        v-for="(pastOrder, index) in pastOrders"
        :key="pastOrder"
        :title="index"
        elevation="0"
        :class="{ panellast: index === Object.keys(pastOrders).length - 1 }"
        ><div style="text-align: end" class="pr-5 pb-2">
          {{
            "合計個数 : " +
            dailyItemCount(pastOrders[index]).toString() +
            " 合計金額 :" +
            dailyTotalPrice(pastOrders[index]).toString() +
            "円"
          }}
        </div>
        <v-expansion-panel-text>
          <v-container>
            <template
              v-for="(_pastOrder, _index) in pastOrders[index]"
              :key="_pastOrder"
              ><v-row>
                <v-divider
                  :thickness="4"
                  class="mt-2 border-opacity-75"
                ></v-divider>
                <template
                  v-for="__pastOrder in pastOrders[index][_index]"
                  :key="__pastOrder"
                >
                  <v-col cols="12" class="pl-0">
                    {{ __pastOrder.ordered_datetime }}
                  </v-col>
                  <v-divider></v-divider>
                  <template
                    v-for="order in __pastOrder.order_items"
                    :key="order"
                    ><v-container>
                      <v-row>
                        <v-col class="px-0">
                          {{ order.title }}{{ order.rice_type }}
                        </v-col>
                        <v-col>
                          {{ order.price.toLocaleString() }} 円 ×
                          {{ order.quantity }} =
                          {{
                            subTotal(
                              order.quantity,
                              order.price
                            ).toLocaleString()
                          }}
                          円
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                  <v-container class="py-0">
                    <v-divider class="my-2"></v-divider>
                    <v-row>
                      <v-col cols="4"></v-col>
                      <v-col cols="3">
                        個数: {{ itemCount(__pastOrder) }}
                      </v-col>
                      <v-col cols="5">
                        小計:
                        {{ total_each_order(__pastOrder).toLocaleString() }}
                        円
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
              </v-row>
            </template>
          </v-container>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-divider :thickness="4" class="mt-2 border-opacity-75"></v-divider>
  </div>
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";

export default {
  name: "CartView",
  data() {
    return {
      orders: {},
      pastOrders: {},
      startDate: new Date(),
      endDate: new Date(),
      format: "yyyy年MM月dd日",
      periodTotalPrice: 0,
      periodTotalItemCount: 0,
    };
  },
  methods: {
    getTodaysOrders() {
      let endpoint =
        this.$store.state.url + "orderlist/" + this.$store.state.slug;
      fetch(endpoint, {
        method: "GET",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
        },
        body: null,
      })
        .then((response) => {
          if (response.status == 200) {
            return response.json();
          } else if (response.status == 404) {
            return null;
          } else {
            return null;
          }
        })
        .then((data) => {
          this.orders = Object.assign({}, data);
        });
    },
    getPastOrders() {
      this.periodTotalItemCount = 0;
      this.periodTotalPrice = 0;
      let endpoint =
        this.$store.state.url + "orderlist/" + this.$store.state.slug;
      fetch(endpoint, {
        method: "POST",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          start_date: moment(this.startDate).format("YYYY-MM-DD"),
          end_date: moment(this.endDate).format("YYYY-MM-DD"),
        }),
      })
        .then((response) => {
          if (response.status == 200) {
            return response.json();
          } else if (response.status == 404) {
            return null;
          } else {
            return null;
          }
        })
        .then((data) => {
          this.orders = {};
          this.pastOrders = {};
          for (const index in data) {
            if (data[index].ordered_date in this.pastOrders) {
              this.pastOrders[data[index].ordered_date].push(
                data[index].order_items
              );
            } else {
              this.pastOrders[data[index].ordered_date] = [
                data[index].order_items,
              ];
            }
          }
          // 過去の注文の合計金額
          for (let key in this.pastOrders) {
            this.periodTotalPrice += this.dailyTotalPrice(this.pastOrders[key]);
            this.periodTotalItemCount += this.dailyItemCount(
              this.pastOrders[key]
            );
          }
        });
    },
    subTotal(quantity, price) {
      return quantity * price;
    },
    itemCount(order) {
      let count = 0;
      for (let order_item in order["order_items"]) {
        count += order["order_items"][order_item].quantity;
      }
      return count;
    },
    dailyItemCount(orders) {
      let count = 0;
      for (let index in orders) {
        for (let _index in orders[index]) {
          count += this.itemCount(orders[index][_index]);
        }
      }
      return count;
    },
    dailyTotalPrice(orders) {
      let totalPrice = 0;
      for (let index in orders) {
        for (let _index in orders[index]) {
          for (let order_item in orders[index][_index]["order_items"]) {
            totalPrice +=
              orders[index][_index]["order_items"][order_item].quantity *
              orders[index][_index]["order_items"][order_item].price;
          }
        }
      }
      return totalPrice;
    },
    total_each_order(order) {
      let total = 0;
      for (let order_item in order["order_items"]) {
        total +=
          order["order_items"][order_item].quantity *
          order["order_items"][order_item].price;
      }
      return total;
    },
  },
  computed: {
    itemTotalCount: function () {
      let totalCount = 0;
      for (let order in this.orders) {
        for (let order_item in this.orders[order]["order_items"]) {
          totalCount += this.orders[order]["order_items"][order_item].quantity;
        }
      }
      return totalCount;
    },
    totalAmmount: function () {
      let ammount = 0;
      for (let order in this.orders) {
        for (let order_item in this.orders[order]["order_items"]) {
          ammount +=
            this.orders[order]["order_items"][order_item].quantity *
            this.orders[order]["order_items"][order_item].price;
        }
      }
      return ammount;
    },
  },
  components: {
    MainHeader,
    datepicker: VueDatePicker,
  },
  created() {
    this.getTodaysOrders();
  },
};
</script>

<style lang="scss">
.panellast {
  border-bottom: 1px solid rgb(237, 28, 36);
}
</style>
