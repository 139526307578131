import { createRouter, createWebHistory } from "vue-router";
import ItemListView from "../views/ItemListView.vue";
import OrderHistoryView from "../views/OrderHistoryView.vue";
import AdminView from "../views/AdminView";
import LoginView from "../views/LoginView";
import AdminStoreMessageView from "../views/AdminStoreMessageView";
import AdminSoldOutView from "../views/AdminSoldOutView";
import store from "../store/index";

const routes = [
  {
    path: "/menu/:slug",
    name: "menu",
    component: ItemListView,
    meta: { title: "配達専用サイト" },
  },
  {
    path: "/orderhistory",
    name: "orderhistory",
    component: OrderHistoryView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/adminsite",
    name: "admin",
    component: AdminView,
    meta: { requiresAuth: true },
  },
  {
    path: "/adminsite/storemessage",
    name: "storemessage",
    component: AdminStoreMessageView,
    meta: { requiresAuth: true },
  },
  {
    path: "/adminsite/soldout",
    name: "soldout",
    component: AdminSoldOutView,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !store.state.accessToken
  ) {
    next({ path: "/login", query: { redirect: to.fullPath } });
  } else {
    next();
  }
});

export default router;
