<template>
  <div>
    <admin-header />
    <v-overlay :model-value="isLoading" class="align-center justify-center">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
  <div class="text-h6 pa-2 ml-2">売切・終売 登録</div>
  <v-text-field
    v-model="searchKeyWords"
    label="検索"
    type="text"
  ></v-text-field>
  <v-container>
    <v-row>
      <v-col
        ><v-switch
          class="px-5"
          color="deep-orange-darken-2"
          v-model="showSoldOutItems"
          value="true"
          label="売切"
          hide-details
          inset
        ></v-switch
      ></v-col>
      <v-col
        ><v-switch
          class="px-5"
          color="deep-orange-darken-2"
          v-model="showSalesEndItems"
          value="true"
          label="終売"
          hide-details
          inset
        ></v-switch
      ></v-col>
    </v-row>
  </v-container>

  <v-table density="compact">
    <thead>
      <tr>
        <th class="text-center">商品名</th>
        <th class="text-center">売切</th>
        <th class="text-center">終売</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in itemList" :key="item.id">
        <template v-if="item.title.includes(searchKeyWords)">
          <template
            v-if="!showSoldOutItems || todaySoldOutItems.includes(item.id)"
          >
            <template
              v-if="!showSalesEndItems || salesEndItems.includes(item.id)"
            >
              <td>{{ item.title }}</td>
              <td>
                <v-btn
                  :disabled="isLoading"
                  v-if="todaySoldOutItems.includes(item.id)"
                  color="red"
                  @click="soldOut(item.id, '売切取消')"
                  >取消</v-btn
                >
                <v-btn
                  :disabled="isLoading"
                  v-else
                  @click="soldOut(item.id, '売切')"
                  >売切</v-btn
                >
              </td>
              <td>
                <v-btn
                  :disabled="isLoading"
                  v-if="salesEndItems.includes(item.id)"
                  color="red"
                  @click="soldOut(item.id, '終売取消')"
                  >取消</v-btn
                >
                <v-btn
                  :disabled="isLoading"
                  v-else
                  @click="soldOut(item.id, '終売')"
                  >終売</v-btn
                >
              </td>
            </template>
          </template>
        </template>
      </tr>
    </tbody>
  </v-table>
</template>

<script>
import AdminHeader from "@/components/AdminHeader.vue";
import { CSRF_TOKEN } from "../common/csrf_token.js";

export default {
  name: "AdminSoldOutView",
  data() {
    return {
      itemList: [],
      todaySoldOutItems: [],
      salesEndItems: [],
      searchKeyWords: "",
      showSoldOutItems: false,
      showSalesEndItems: false,
      isLoading: false,
    };
  },
  components: {
    AdminHeader,
  },
  methods: {
    getItemData() {
      let endpoint = this.$store.state.url + "adminsite/data";
      fetch(endpoint, {
        method: "GET",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
          "X-CSRFTOKEN": CSRF_TOKEN,
          Authorization: "JWT " + this.$store.state.accessToken,
        },
        body: null,
      })
        .then((response) => {
          if (response.status == 200) {
            return response.json();
          } else if (response.status == 401) {
            this.$store.commit("setAccessToken");
            this.getItemData();
          } else {
            return null;
          }
        })
        .then((data) => {
          console.log(data);
          this.itemList = data["item_list"];
          data["sales_end_items"].forEach((e) => {
            this.salesEndItems.push(e.item);
          });
          data["sold_out_items"].forEach((e) => {
            this.todaySoldOutItems.push(e.item);
          });
        });
    },
    soldOut(id, type) {
      this.isLoading = true;
      let endpoint = this.$store.state.url + "adminsite/soldout";
      fetch(endpoint, {
        method: "POST",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
          "X-CSRFTOKEN": CSRF_TOKEN,
          Authorization: "JWT " + this.$store.state.accessToken,
        },
        body: JSON.stringify({ id: id, type: type }),
      }).then((response) => {
        if (response.status == 201) {
          if (type == "売切取消") {
            const idIndex = this.todaySoldOutItems.indexOf(id);
            this.todaySoldOutItems.splice(idIndex, 1);
          } else if (type == "終売取消") {
            const idIndex = this.salesEndItems.indexOf(id);
            this.salesEndItems.splice(idIndex, 1);
          } else if (type == "売切") {
            this.todaySoldOutItems.push(id);
          } else if (type == "終売") {
            this.salesEndItems.push(id);
          }
        } else if (response.status == 401) {
          this.$store.commit("setAccessToken");
          this.soldOut(id, type);
        } else {
          return null;
        }
        this.isLoading = false;
      });
    },
  },
  created() {
    this.getItemData();
  },
};
</script>
